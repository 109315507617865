jQuery(function($) {
    /**
     * set rootURL, find if there is a language string in the url, store in a cookie
     */
    var supportedLanguages = ['en', 'sk'];
    var defaultTheme = 'blue-green';
    var paths = window.location.pathname.split('/');
    var lang = 'sk';
    var rootURL = '/';
    var langURL = '';
    for (var i = 0; i < paths.length; i++) {
        if (paths[i] !== '' && supportedLanguages.indexOf(paths[i]) > -1) {
            lang = paths[i];
            langURL = lang + '/';
            if (i > 1) {
                rootURL = '/' + paths[i-1] + '/';
            }
        }
    }
    if (rootURL === '/' && paths.length > 2) {
        rootURL = '/' + paths[1] + '/';
    }
    $.cookie('fixit_lang', lang, { expires: 3650, path: '/' });

    $('.top-menu--flag-uk a').click(function() {
        $.cookie('fixit_lang', 'en', { expires: 3650, path: '/' });
    });
    $('.top-menu--flag-sk a').click(function() {
        $.cookie('fixit_lang', 'sk', { expires: 3650, path: '/' });
    });

    /**
     * on resize, set elements to height/width with <div copy-size="#element-with-height">
     */
    $(document).ready(function() {
        copyElementSize();
    });
    $(window).resize(function() {
        copyElementSize();
    });

    function copyElementSize() {
        $('[copy-size]').each(function() {
            var copyEl = $($(this).attr('copy-size'));
            var targetEl = $(this);
            targetEl.width(copyEl.width() + 'px');
            targetEl.height(copyEl.height() + 'px');
            targetEl.offset({top: copyEl.position().top, left: copyEl.position().left});
        });
    }


    /**
     * themes
     */
    var supportedThemes = [
        'purple-blue', 'black-blue', 'blue-blue', 'blue-green',
        'green-blue', 'green-green', 'green-yellow', 'pink-red',
        'purple-blue', 'purple-greenish', 'red-blue' //, 'orange-yellow'
    ];
    var theme = $.cookie('fixit_theme');
    if (!theme) {
        // theme = supportedThemes[Math.floor(Math.random()*supportedThemes.length)];
        theme = defaultTheme;
    }

    function loadCSS(theme) {
        theme = theme.replace('theme-', '');
        var path = rootURL.replace(langURL, '') + 'css/theme-' + theme + '.min.css';

        // var link = $('#fixit-theme');
        var link = document.getElementById('fixit-theme');
        if (!link) {
            link = document.createElement("link");
            link.id = "fixit-theme";
            link.type = "text/css";
            link.rel = "stylesheet";
            $('head').append(link);
        }
        var oldTheme = $.cookie('fixit_theme') || 'blue-blue';
        // if (theme !== oldTheme) {
            link.href = path;
            $('.theme-selector--dropdown').removeClass('theme-' + oldTheme).addClass('theme-' + theme);
            $.cookie('fixit_theme', theme, { expires: 3650, path: '/' });
        // }
    }

    $(document).ready(function() {
        // loadCSS(theme);
        $('.theme-selector').each(function() {
            var classList = $(this).attr('class').split(/\s+/);
            var theme = classList.find(function(x) { return x !== 'theme-selector'});
            $(this).click(function() { loadCSS(theme); });
        });

    });


    /**
     * cookie consent
     */
    if (!$.cookie('fixit_cookieagreed'))
    {
        var t_cookies = {
            'sk': {
                msg: 'Táto stránka používa súbory cookies.',
                ok: "OK",
                settings: 'Nastavenia'
            },
            'en': {
                msg: 'This website uses cookies.',
                ok: "OK",
                settings: 'Settings'
            }
        };
        // t_msg + ' <a href="https://www.google.com/intl/sk/policies/privacy/partners/" target="_blank">' +
        // t_settings + '</a></div>' +
        var cBody = '<div class="cookies-wrapper"><div class="cookies-content"><div class="cookies-msg"><h3>' + t_cookies[lang]['msg'] +'</h3></div>' +
            '<button id="cookiesBtn" class="cookies-btn cookies-btn--setings btn btn-default" data-toggle="modal" data-target="#gdpr" role="button">' + t_cookies[lang]['settings'] + '</button>' +
            '<div class="cookies-btn cookies-btn--ok btn btn-secondary">' + t_cookies[lang]['ok'] + '</div></div></div>';
        $('body').append(cBody);
    }
    $('.cookies-btn--ok').click(cookieAgreedAll);
    $('#gdpr .gdpr-accept-all').click(cookieAgreedAll);
    $('#gdpr .gdpr-accept-selected').click(cookieAgreedSelected);

    function cookieAgreedSelected() {
        var options = ['neccessary'];
        if ($('#gdpr_analytics').prop('checked')) {
            options.push('analytics');
        }
        return cookieAgreed(options);
    }

    function cookieAgreedAll() {
        return cookieAgreed(['neccessary', 'analytics']);
    }

    function cookieAgreed(options) {
        $.cookie('fixit_cookieagreed', options.join(','), { expires: 3650, path: '/' });
        $('body > .cookies-wrapper').toggleClass('hidden');
    }

    /**
     * scroll to hash element after load
     */
    function scrollToElement(el, time)
    {
        if (time === undefined)
        {
            time = 500;
        }
        
        heightHeaderNav = $('header .inside nav').height();
        
        $('html, body').animate({
            scrollTop: el.offset().top - (heightHeaderNav + 0)
        }, time);
    } 
    
    var hash = location.hash;
    if (location.hash !== '')
    {
        el = hash.replace(/(\/|)#/, '#');
        if ($(el).length > 0)
        {
            setTimeout(function() {
                scrollToElement($(el));
            }, 1000);
        }
    }

    /**
     * initiative Google map
     */
    function initMap()
    {
        var maps = [];
        maps[0] = ['48.159347,17.119943', '<strong>FixIT</strong><br>J. C. Hronského 4<br>831 02 Bratislava'];
        maps[1] = ['48.122336,17.103293', '<strong>FixIT</strong><br>Belinského 16<br>851 01 Bratislava'];
    
        var center_changed = new google.maps.LatLng(48.146211, 17.12);
    
        var mapCanvas = document.getElementById('map');
        var mapOptions = {
          center: center_changed,
          zoom: 13,
          panControl: false,
          mapTypeId: google.maps.MapTypeId.ROADMAP
        }
        var map = new google.maps.Map(mapCanvas, mapOptions);
    
        var infowindow = new google.maps.InfoWindow();
        
        var marker, i;
          
        for (i = 0; i < maps.length; i++)
        {
          maplatlng = maps[i][0].split(',');
          var location = new google.maps.LatLng(maplatlng[0], maplatlng[1]);
    
          marker = new google.maps.Marker({
            position: location,
            map: map
          });
         
          (function(marker, i) {
            google.maps.event.addListener(marker, 'click', function() {
                infowindow = new google.maps.InfoWindow({
                    content: maps[i][1],
                    maxWidth: 200
                });
                infowindow.open(map, marker);
            });
          })(marker, i);
        }
        
        /*var location = new google.maps.LatLng(48.122336, 17.103293);
        var center_changed = new google.maps.LatLng(48.142211, 17.101713);
    
        var mapCanvas = document.getElementById('map');
        var mapOptions = {
          center: center_changed,
          zoom: 13,
          panControl: false,
          mapTypeId: google.maps.MapTypeId.ROADMAP
        }
        var map = new google.maps.Map(mapCanvas, mapOptions);
    
        var marker = new google.maps.Marker({
            position: location,
            map: map
        });
    
        var contentString = '<strong>FixIT</strong><br>Belinského 16<br>851 01 Bratislava';
    
        var infowindow = new google.maps.InfoWindow({
          content: contentString,
          maxWidth: 200
        });
    
        infowindow.open(map, marker);*/
    }

    function registerMap() {
        google.maps.event.addDomListener(window, 'load', initMap);
    }

    if ($('#map').length) {
        registerMap();
    }

    /**
     * contact form
     */
    $('#form-contact a').click(function() {
        $(this).closest('form').find('.form-contact--invalid').addClass('hidden');
        
        $.ajax({
            url: rootURL + 'includes/contact_form.php',
            method: 'post',
            dataType: 'json',
            data: $(this).closest('form').serialize()
        }).done(function(result) {
            //console.log(result);
            if (result == 1)
            {
                $('#form-contact--success').removeClass('hidden');
                $('#form-contact input, #form-contact textarea').val('');
                
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({'event' : 'formular'});
            }
            else if (result == 2)
            {
                $('#form-contact--unsuccess').removeClass('hidden');
            }
            else
            {
                for (i = 0; i < result.length; i++)
                {
                    if (result[i] == 'g-recaptcha-response')
                    {
                        $('.g-recaptcha').next('.form-contact--invalid').removeClass('hidden');
                    }
                    else if (result[i] == 'consent')
                    {
                        $('.checkbox--consent .form-contact--invalid').removeClass('hidden');
                    }
                    else
                    {
                        $('#' + result[i]).next('.form-contact--invalid').removeClass('hidden');
                    }
                }
            }
        });
        
        grecaptcha.reset();
    });
    
    
    /**
     * scroll to element
     */
    // $('.navbar-header--top .top-menu--location').click(function() {
      // $('html, body').animate({
      //   scrollTop: $('#contact').offset().top
      // }, 500);
    // });
    
  
    /**
     * mouseover logo
     */
    $('.logo-list li').hover(function() {
        img = $(this).find('img');
        hoverImg = img.data('hover');
        if (hoverImg.length > 0)
        {
            img.data('hover', img.attr('src'));
            img.attr('src', hoverImg);
            
        }
    }, function() {
        img = $(this).find('img');
        defaultImg = img.data('hover');
        if (defaultImg.length > 0)
        {
            img.data('hover', img.attr('src'));
            img.attr('src', defaultImg);
        }
    });
    
    
    /**
     * back top button
     */
    $(function () {
        $(window).scroll(function () {
            if ($(this).scrollTop() > 100)
            {
                $('#back-top').fadeIn();
            }
            else
            {
                $('#back-top').fadeOut();
            }
        });
    });

    $('#back-top').click(function() {
        $('body,html').animate({
            scrollTop: 0
        }, 800);
        
        return false;
    });

    $('#fixit-contacts-btn-scroll').click(function() {
        $('body,html').animate({
            scrollTop: ($('#fixit-contacts').offset().top)
        }, 800);

        return false;
    });

    /**
     * search spare parts
     */
    $('#form-search-spare-parts input[type=text]').autocomplete({
        source: function(request, response) {
            $.ajax({
                url: rootURL + 'includes/get_spare_parts.php',
                data: {
                    c: request.term
                },
                success: function(data) {
                    response(data);
                }
            });
        },
        minLength: 0,
        select: function(event, ui) {
            window.location.hash = '#' + encodeURIComponent(ui.item.value);
            generateSparePartsResults(ui.item.value);
        },
        search: function() {
        } 
    }).click(function() {
        $(this).autocomplete('search');
    });
    
    function generateSparePartsResults(category)
    {
        if (category === undefined) return;
        
        $.ajax({
            url: rootURL + 'includes/get_spare_parts.php',
            data: {
                p: category
            },
            success: function(data) {
                $container = $('#service-repair--list--container');
                $container.html('');
                
                $output = '';
                $output += '<h2 class="text-center font-gradient mar-bottom-30">' + category + '</h2>';
                $output += '<ul class="service-repair--list">';
                $(data).each(function(index, value) {
                    $price = value.price;
                    $output += '<li><span>' + value.name + '</span> <span><b>' + $price.replace(/\./, ',') + ' &euro;</b></span></li>';
                });
                $output += '</ul';
                
                $container.append($output);
            }
        });
    }
    
    var thisHash = decodeURIComponent(window.location.hash).replace(/#/, '');
    var thisURI = window.location.pathname;
    if (thisHash && thisURI.match(/nahradne-diely/g))
    {
        $('#form-search-spare-parts input').val(thisHash);
        generateSparePartsResults(thisHash);
    }

    /**
     * search phones
     */
    $('#form-search-phones input[type=text]').autocomplete({
        source: function(request, response) {
            $.ajax({
                url: rootURL + 'includes/get_phones.php',
                data: {
                    c: request.term
                },
                success: function(data) {
                    response(data);
                }
            });
        },
        minLength: 0,
        select: function(event, ui) {
            window.location.href = ui.item.slug;
        },
        search: function() {
        } 
    }).click(function() {
        $(this).autocomplete('search');
    }).on('keydown', function(event) {
        if (event.keyCode == 13)
        {
            event.preventDefault();
            return false;
        }
    });
    
    /**
     * search phones by current brand
     */
    $('#form-search-phones-brand input[type=text]').autocomplete({
        source: function(request, response) {
            $.ajax({
                url: rootURL + 'includes/get_phones.php',
                data: {
                    c: request.term,
                    b: $('#b').val()
                },
                success: function(data) {
                    response(data);
                }
            });
        },
        minLength: 0,
        select: function(event, ui) {
            window.location.href = ui.item.slug;
        },
        search: function() {
        } 
    }).click(function() {
        $(this).autocomplete('search');
    }).on('keydown', function(event) {
        if (event.keyCode == 13)
        {
            event.preventDefault();
            return false;
        }
    });
    
    /**
     * search phone repair
     */
    $('#form-search-repair input[type=text]').autocomplete({
        source: function(request, response) {
            $.ajax({
                url: rootURL + 'includes/get_phone_repair.php',
                data: {
                    r: request.term,
                    b: $('#p').val()
                },
                success: function(data) {
                    response(data);
                }
            });
        },
        minLength: 0,
        select: function(event, ui) {
            window.location.href = ui.item.slug;
        },
        search: function() {
        } 
    }).click(function() {
        $(this).autocomplete('search');
    }).on('keydown', function(event) {
        if (event.keyCode == 13)
        {
            event.preventDefault();
            return false;
        }
    });
    
    /**
     * search fulltext
     */
    $('#form-search-fulltext input[type=text]').autocomplete({
        source: function(request, response) {
            $.ajax({
                url: rootURL + 'includes/search_fulltext.php',
                data: {
                    search: request.term
                },
                success: function(data) {
                    response(data);
                }
            });
        },
        minLength: 3,
        select: function(event, ui) {
            window.location.href = ui.item.slug;
        } 
    }).click(function() {
        $(this).autocomplete('search');
    }).on('keydown', function(event) {
        if (event.keyCode == 13)
        {
            event.preventDefault();
            return false;
        }
    }).focusin(function(event) {
        $('.navbar-header--top .top-menu-location--city').hide();
    }).focusout(function(event) {
        $('.navbar-header--top .top-menu-location--city').show();
    });

    /**
     * add repair act to order / cancel repair act from order
     */
    $('#add_to_order, .order_cancel').click(function() {
        $.ajax({
            url: rootURL + 'includes/repair_to_order.php',
            data: {
                action: $(this).data('action'),
                phone: $(this).data('phone'),
                act: $(this).data('repair-act'),
                price: $(this).data('price')
            },
            success: function(data) {
                if (data == 'reload')
                {
                    location.reload();
                }
            }
        });
    });

    /**
     * reload page for calculate order status
     */
    $('#addToOrderModal').on("hide.bs.modal", function() {
        location.reload();
    });
    
    /**
     * order form
     */
    $('#form-order a').click(function() {
        $(this).closest('form').find('.form-order--invalid').addClass('hidden');
        
        $.ajax({
            url: rootURL + 'includes/order_form.php',
            method: 'post',
            dataType: 'json',
            data: $(this).closest('form').serialize()
        }).done(function(result) {
            if (result == 1)
            {
                $('#form-order--success').removeClass('hidden');
                $('#form-order input, #form-order textarea').val('');
                
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({'event' : 'formular'});
            }
            else if (result == 2)
            {
                $('#form-order--unsuccess').removeClass('hidden');
            }
            else
            {
                for (i = 0; i < result.length; i++)
                {
                    if (result[i] == 'g-recaptcha-response')
                    {
                        $('.g-recaptcha').next('.form-order--invalid').removeClass('hidden');
                    }
                    else
                    {
                        $('#' + result[i]).next('.form-order--invalid').removeClass('hidden');
                    }
                }
            }
        });
        
        if ($('.g-recaptcha').length)
        {
            grecaptcha.reset();
        }
    });

});
